
import { defineComponent } from 'vue';
export default defineComponent({
    name:"title-time",
    data() {
        return {
            hours: "",
            minutes: ""
        }
    },
    methods: {
        getTimeNow() {
            const zerofilled = (num:number) => ('00' + num).slice(-2);
            const dateObj =  new Date();
            this.hours = zerofilled(dateObj.getHours());
            this.minutes = zerofilled(dateObj.getMinutes());
        }
    },
    created() {
        this.getTimeNow();
        setInterval(this.getTimeNow, 1000);
    }
});
