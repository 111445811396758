
import { defineComponent } from 'vue';
export default defineComponent({
    name: "search-bar",
    data() {
        return {
            focus: false
        }
    },
    props: {
        modelValue: {
            type: String,
            default: "",
            required: true
        },
        suggestWord: {
            type: String,
            default: "",
            required: true,
        }
    },
    methods: {
        goSearch() {
            if (!this.modelValue) return;
            if (this.suggestWord) {
                window.location.href = `https://www.bing.com/search?q=${this.suggestWord}`;
            } else {
                window.location.href = `https://www.bing.com/search?q=${this.modelValue}`;
            }
        }
    }
});
