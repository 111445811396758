
import TimeVue from './components/TitleTime.vue';
import SearchBarVue from './components/SearchBar.vue';
import searchSuggestionVue from './components/searchSuggestion.vue';
export default {
  components: {TimeVue, SearchBarVue, searchSuggestionVue},
  data() {
    return {
      searchFocus: false,
      searchBarValue: "",
      suggestSelected: -1,
      suggestKeyword: ""
    }
  },
  created() {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }
}
