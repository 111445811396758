
import axios from "axios";
import { defineComponent } from "vue";
import { ComponentPublicInstance } from 'vue';
import _ from 'lodash';

interface MyComponent extends ComponentPublicInstance {
  getSuggests: (value: string) => void;
}
export default defineComponent({
  data() {
    return {
      suggests: [] as string[],
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    searchBarValue: {
      type: String,
      required: true,
    },
    suggestSelected: {
      type: Number,
      required: true,
    },
  },
  watch: {
    searchBarValue: {
      handler: _.throttle(function(this: MyComponent, newValue: string) {
        this.getSuggests(newValue);
      }, 500), // Adjust the delay (in milliseconds) as per your needs
      immediate: false
    },
    // show() {
    //   this.getSuggests(this.searchBarValue);
    // },
    suggestSelected() {
      this.$emit(
        "update-searchbar-value",
        this.suggests[this.toIndex(this.suggestSelected)]
      );
    },
  },
  methods: {
    toIndex(val: number): number {
      if (val >= 0) {
        return val % this.suggests.length;
      } else {
        return this.toIndex(val + this.suggests.length);
      }
    },
    getSuggests(keyword: string) {
      if (keyword == '') {
        return;
      }
      axios.get('https://startpage.ladbaby.top/cors/https://www.google.com/complete/search?client=chrome&q=' + keyword, {
        headers: {
            "X-Requested-With": "XMLHttpRequest"
        }
      }).then((result) => {
          if (result.data[1].length > 0) this.suggests = result.data[1];
      });
    },
  },
});
