import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "searchSuggestion",
  style: {"height":"270px"}
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggests, (item, index) => {
          return (_openBlock(), _createElementBlock("a", {
            href: 'https://www.bing.com/search?q=' + item,
            key: index,
            class: _normalizeClass({focus:index == _ctx.toIndex(_ctx.suggestSelected as number)})
          }, _toDisplayString(item), 11, _hoisted_2))
        }), 128))
      ], 512), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }))
}